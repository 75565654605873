import React, { Component } from 'react';
import BradsMenu from './BradsMenu';
import './Portfoilo.css';
import logo from './signature.svg';
import './home.scss';
import './brad (2).jpg';
import './bartender.jpg';
import './falls.jpg';
import './tiger.jpg';
import resume from './BradCiechanowskiDeveloper.docx'


class Resume extends Component {
    id = 2;

    render() {
        let links = [
            { label: 'Home', link: 'Home' },
            { label: 'Resume', link: 'Resume', active: true },
            { label: 'Products', link: 'Products' },
            { label: 'Contact', link: 'Contact' },
        ];
        const Aux = (props) => {
            return props.children;
        };
        return (
            <Aux>
                <div className="container center">
                    <BradsMenu links={links} logo={logo} />
                </div>
                <div className="wrapper1 center">
                    <div className="column">
                        <div className="center">
                            <img src={require('./brad (2).jpg')} alt="bar" />
                        </div>
                        <div>
                            <div className="section-wrapper">
                                <h3 className="section-title">Skills</h3>
                                <div className="skill-percentage">HTML / HTML5</div>
                                <div className="skill-percentage">CSS / CSS3 / SASS</div>
                                <div className="skill-percentage">Javascript</div>
                                <div className="skill-percentage">Jquery</div>
                                <div className="skill-percentage">Websocket APIs</div>
                                <div className="skill-percentage">Rest APIs</div>
                                <div className="skill-percentage">C++</div>
                                <div className="skill-percentage">Java</div>
                                <div className="skill-percentage">React.js</div>
                                <div className="skill-percentage">Node.js</div>
                                <div className="skill-percentage">C#</div>
                                <div className="skill-percentage">Python</div>
                                <div className="skill-percentage">Golang</div>
                                <div className="skill-percentage">Git</div>
                                <div className="skill-percentage">Angular</div>
                            </div>
                        </div>
                    </div>
                    <div className="column expcolumn">
                        <div>
                            <section className="experience section-padding">
                                <div className="container">
                                    <h3 className="experience-title">Experience</h3>

                                    <div className="experience-wrapper">
                                        <div className="company-wrapper">
                                            <div className="experience-title">Lowe's</div>
                                            <div className="time">Jan 2021 - Present</div>
                                        </div>

                                        <div className="job-wrapper ">
                                            <div className="experience-title">Software Developer</div>
                                            <div className="company-description">
                                                <p>Frontend and Backend developer that works in the Vendor Gateway section of the application. Handle all Authentication and app integrations for the application. Our stack includes React.js, Node.js, express.js, html, CSS, JAVA and Restful APIs. Maintain relevant GCP pipelines and problem-solve team issues.</p>
                                            </div>
                                        </div>

                                        <div className="company-wrapper ">
                                            <div className="experience-title">Purple Land Management</div>
                                            <div className="time">Aug 2019 - Jan 2021</div>
                                        </div>

                                        <div className="job-wrapper ">
                                            <div className="experience-title">Software Engineer</div>
                                            <div className="company-description">
                                                <p>Worked for Oil and gas Company where I designed and coded a website and web application using React.js, with Node.js / express backend that interacts with a SQL server which is all deployed on Microsoft Azure. Integrated google firebase for authentication and connected it with the SQL database. Designed all UI.</p>
                                            </div>
                                        </div>

                                        <div className="company-wrapper">
                                            <div className="experience-title">Double Eagle Development</div>
                                            <div className="time">Jun 2018 - Sept 2019</div>
                                        </div>

                                        <div className="job-wrapper ">
                                            <div className="experience-title">Software Engineer</div>
                                            <div className="company-description">
                                                <p>Designed and coded multiple web applications for specific departments using React.js, with Node.js / express backend that interacts with a SQL server which is all deployed on Microsoft Azure. Saved the company 100,000 in unnecessary fees and labor.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <h3 className="experience-title">Education</h3>

                                    <div className="experience-wrapper">
                                        <div className="company-wrapper ">
                                            <div className="experience-title">Purdue University</div>
                                            <div className="time">Jan 2016 - Dec 2019</div>
                                        </div>

                                        <div className="job-wrapper">
                                            <div className="experience-title">Bachelor of Computer Science</div>
                                            <div className="company-description">
                                                <p>Graduated with Bachelors of Computer Science in Information Technology. 3.95 GPA and President's List for 6 Semesters. Completed Research in Server Communication and completed training in Project Management.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="spacing">
                    <a
                        href={resume}
                        download="BradCiechanowskiDeveloper"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="button1">Download Resume</button>
                    </a>
                </div>
            </Aux>

        );
    }
}
export default Resume;
